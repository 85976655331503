.A11yNavigation a {
  position: absolute;
  top: -200px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 0;
  z-index: 1000;
  padding: 1em;
  @apply bg-black text-white;
}

.A11yNavigation a:focus {
  clip: auto;
  top: 0;
  overflow: visible;
  height: auto;
  outline: 0;
}
